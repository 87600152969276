.App {
  font-family: sans-serif;
  text-align: center;
}
.button1 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button1:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button1:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
.button2 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 10;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 150px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.button2:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button2:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background-image: url("supercontrollerbackground.png");
  background-position: center;
}

body .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 40px 0;
}

body .container .card {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

body .container .card:nth-child(1) .box .content a {
  background: #094e4b;
}

body .container .card:nth-child(2) .box .content a {
  background: #534e00;
}

body .container .card:nth-child(3) .box .content a {
  background: #c17723;
}

body .container .card:nth-child(4) .box .content a {
  background: #c1239a;
}
body .container .card:nth-child(1) .box .content b {
  background: #094e4b;
}

body .container .card:nth-child(2) .box .content b {
  background: #534e00;
}

body .container .card:nth-child(3) .box .content b {
  background: #c17723;
}

body .container .card:nth-child(4) .box .content b {
  background: #c1239a;
}
body .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

body .container .card .box:hover {
  transform: translateY(-50px);
}

body .container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .container .card .box .content {
  padding: 20px;
  text-align: center;
}

body .container .card .box .content h2 {/*game icon*/
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 7rem;
  color: rgba(255, 255, 255, 0.1);
  z-index: -3;
}

body .container .card .box .content h3 {/*game title*/
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: black;
}

body .container .card .box .content p {/*game description*/
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}
body .container .card .box .content a {/*play button*/
  position: relative;
  display: inline-block;
  padding: 8px 15px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  margin-left: 7px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
body .container .card .box .content b {/*tournament button*/
  position: relative;
  display: inline-block;
  padding: 8px 15px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  margin-left: 7px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
body .container .card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
body .container .card .box .content b:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
.wallet-address {
  position: fixed;
  top: 10px;
  right: 170px;
  font-size: 18px;
  font-weight: bold;
  color: #e6e1e1;
  background-color: rgba(128, 128, 128, 01);
  padding: 5px 10px;
  border-radius: 10px;
  z-index: 8;
}
.Modal {
  position: relative;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.Modal-content {
  background-color: #3b3a3a;
  margin: 15% auto;
  padding: 20px;
  border: 5px solid #6d6c6c;
  width: 80%;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #fefefe;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}
@media (max-width: 768px) {
  .wallet-address {
    font-size: 14px;
    right: 180px;
    padding: 3px 6px;
  }
}

@media (max-width: 480px) {
  .wallet-address {
    font-size: 14px;
    right: 55px;
    padding: 5px;
    top: 50px;
}
}

.success-message {
  position: relative;
  display: inline-block;
  padding: 8px 15px;
  background: rgb(100, 211, 10);
  border-radius: 5px;
  text-decoration: none;
  color: rgb(0, 0, 0);
  margin-top: 20px;
  margin-left: 7px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.button3 {
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  color: #e6e1e1;
  background-color: rgb(100, 100, 100);
  padding: 7px 10px;
  border-radius: 10px;
  cursor:pointer;
}
.buttonbk {
  position: relative;
  font-size: 10px;
  font-weight: bold;
  color: #e6e1e1;
  background-color: rgb(61, 61, 61);
  padding: 5px 10px;
  border-radius: 10px;
}
button4 {
  position: relative;
  font-size: 10px;
  font-weight: bold;
  color: #e6e1e1;
  background-color: rgba(128, 128, 128, 01);
  padding: 5px 10px;
  border-radius: 10px;
}
.provider{
  background-color: #2a2b2f;
  margin: 5% auto;
  padding: 15px;
  border: 30px transparent #6d6c6c;
  width: 50%;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  color: #fefefe;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 